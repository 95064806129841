@import "../../scss/breakpoints";

/* sm사이즈일때 GNB 안보임 */
.TopHeaderBar {
  display: none;
  width: 100%;
  position: absolute;
  height: 42px;
  background-color: rgba(0, 0, 0, .6);
  z-index: 10;

  @include media-breakpoint-down(sm) {
    display: flex;
  }
  /*햄버거버튼*/
  .HambargWrap {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
    }

    .Hambarg {
      position: absolute;
      background: none;
      border: 0;
      &:focus {
        outline: none;
      }
    }
  }

  /*디자인디비 로고*/
  .BrandImage {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
    img {
      margin-left: 15px;
    }
  }

  /*로그인,회원가입 링크*/
  .RightLinkWrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .RightLink {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      height: 12px;
      border-right: 1px solid #fff;

      a, a:hover {
        color: #fff;
        font-size: 12px;
        text-decoration: none;
      }
    }
    .RightLink:last-child {
      border-right: 0;
    }
  }

}
.GNB {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  //background-color: #000;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  background-color: white;

  .Brand {
    img {

      width: 150px;
    }
  }

  /* 메뉴 네비게이션 */

  .Menus {
    font-weight: 900;

    .Menu {
      font-size: 1.1em;

    }

    &:hover {
      color: #000;
      cursor: pointer;
    }

    .NavLink {
      color: rgb(112, 112, 112);
      outline: none;


      &:hover {
        color: #000;
        cursor: pointer;
      }


      span {
        transition: color .3s;
      }

      &.Active {
        color: #ffe300;
      }
    }
    .LightTheme {
      color: #000;
    }
  }
}









