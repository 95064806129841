@import '../../scss/breakpoints';

.LoginDialog{
  max-width:738px;
  z-index: 99999;
}

.CloseDiv{
  padding-top: 5px;
  padding-left: 17px;
  padding-right: 10px;
  text-align: left;
  img{
    display: none;
    @include media-breakpoint-down(sm) {
      display: inline;
    }
    width: 100px;
    height: 20px;
  }

}

.CloseButton{
  cursor: pointer;
  float: right;
}
.Content{
  margin-left: auto;
  margin-right: auto;
  width : 58%;
  @include media-breakpoint-down(sm) {
    width : 90%;
  }
}
.TitleDiv{
  display: flex;
  font-size: 14px;
  padding-top: 80px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  justify-content: center;
  padding-bottom: 12px;
}

.Title{
  font-size: 24px;
  text-align: center;
  letter-spacing: -3px;
}

.LoginInput{
  border-color:black;
}
.LoginButton{
  width: 100%;
  color: white;
  background-color: black;
  border-radius: 0;

}

.LinkDiv{
  display: flex;
  > a {
    flex:1;
    font-size: 12px;
    color: #000;
  }
}

.LogoDiv{
  text-align: center;
  justify-content: center;
  display: flex;
  img {
    width: 100px;
    height: 20px;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
.TopText{
  font-size: 11px;
}
.UnderText{
  font-size: 3.5px;
}