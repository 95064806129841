@import '../scss/breakpoints';





.title {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 40px;
  font-size: 1.6em;
  font-weight: 800;


  .redPoint {
    display: inline-block;
    opacity: 1;
    right: 0;
    top: 19px;
    content: "";
    border-radius: 50%;
    background-color: #f32938;
    width: 7px;
    height: 7px;
  }
}


header {
  width: 100%;
  height: 100%;
  background: url("/asset/img/main_dog.jpg") no-repeat;
  background-size: cover;

  @include media-breakpoint-down(sm) {
    display: grid;
    height: 100%;
    background: url("/asset/img/maindogM.jpg") no-repeat;
    background-size: cover;
  }


  /* Carousel 슬라이드 스타일 */

  .carouselBox {
    overflow: hidden;
    width: 600px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .projectTag {

    span{
      font-size: 1.2em;
      font-weight: normal;
      padding: 5px 15px;
      margin-right: 12px;
      background-color: #ff0000;
      border-radius: 30px;
      color: #ffffff;
      margin-bottom: 40px;
    }

    p {
      color: #e9ecef;
      margin-top: 20px;
      margin-bottom: 60px;
      line-height: 1.5em;
    }

  }



  /* sm사이즈일때 GNB 안보임 */
  .GNB {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

}





.googleMap {
  width: 100%;
  height: 180px;
  margin-bottom: 20px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}



