@import '../../scss/breakpoints';

/*사이드바 패널*/
.SideBar {
  z-index: 1040;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 1);
  transition: width 50ms linear;

  display: flex;
  flex-direction: column;

  &.active {
    width: 0;
    @include media-breakpoint-down(sm) {
      width: 100%;
      height: 100%;
    }
  }


  .SideBarHeader {
    display: flex;

    .BrandImage {
      flex-grow: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        margin-left: 50px;
      }
      img {
        margin-left: 15px;
      }
    }
  }

  .SideBarBody {
    padding: 15px;

    .SideBarMenu {


      button {
        font-size: 1.2em;
        font-weight: 900;
        color: white;
        padding: 0 12px;

        span {
          margin-left: 10px;
          color: white;
        }
      }

      .SideBarSubMenu {
        padding-left: 60px;


        button {
          font-size: 0.75em;
          font-weight: 400;
          color: white;
          padding: 0 12px;
        }
      }
    }
  }
}

.LoginDiv{
  padding: 35px;
  a{

    margin-left: 10px;
    font-size: 1.2em;
    font-weight: 900;
    color: white !important;
    height: 40px;
  }
}