* {
    box-sizing: border-box;
    font-family: "NanumSquareRound", sans-serif;
    color: rgb(112, 112, 112);;
}


.clearfix::after {
    box-sizing: border-box;
    clear: both;
    content: '';
}

body {
    list-style: none;
    line-height: 1.6em;
    background-color: #fff;
}



@media (min-width: 768px) {
    .modal-dialog {
        width: 700px;
    }
}

@media (min-width: 1000px) {
    .modal-dialog {
        width: 1000px;
    }
}

.search-panel button {
    margin-left: 5px;
}

.detail-view label {
    display: block;
}

.detail-view .Required {
    padding-top: 5px;
    padding-bottom: 5px;
}

.detail-view .Required label::before {
    content: '*';
    color: red;
    position: relative;
    margin-right: 5px;
}

.detail-view .tooltip {
    position: absolute;
    top: -25px;
}

.detail-view .RadioGroup {
    padding-top: 5px;
    padding-bottom: 5px;

}

.detail-view .RadioGroup label {
    display: inline-block;
}

label {
    margin-bottom: 0;
}

.table>thead>tr>th {
    vertical-align: middle;
}

/* 컨테이너 너비 */
@media (min-width: 1200px) {
    .container {
        max-width: 1260px;
    }
}

.popup {
    position : fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
}

.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}

.carousel,.item,.active{height:100%;}
.carousel-inner{height:100%;}
.carousel-inner>.item>img {
    height:100%;
}
.carousel-control-prev{
    z-index: 9999;
}
.carousel-control-next{
    z-index: 9999;
}